import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Form from "./pages/form";

const App = function () {
  return (
    <Routes>
      <Route path="/" element={<Form />} />
    </Routes>
  );
};

export default App;
