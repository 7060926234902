import { useLocation } from "react-router-dom";

function Form() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const source = encodeURI(
    params.get("form") +
      "&prefill_" +
      params.get("field") +
      "=" +
      params.get("value")
  );

  return (
    <div>
      <iframe
        title={"Airtable form"}
        class={"airtable-embed"}
        src={source}
        frameborder={"0"}
        onmousewheel={""}
        width={"100%"}
        height={"1000px"}
        style={{ background: "transparent", border: "1px solid #ccc" }}
      ></iframe>
    </div>
  );
}

export default Form;
